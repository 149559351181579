@tailwind base;
@tailwind components;
@tailwind utilities;



/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&family=Poppins:wght@400;500&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  --header-height: 3.5rem;

  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --title-color: hsl(0, 0%, 95%);
  --text-color: hsl(0, 0%, 70%);
  --text-color-light: hsl(0, 0%, 60%);
  --body-color: hsl(0, 0%, 0%);
  --container-color: hsl(0, 0%, 8%);

  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: "Poppins", sans-serif;
  --second-font: "Montserrat", sans-serif;
  --biggest-font-size: 2.75rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: .938rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;

  /*========== Font weight ==========*/
  --font-regular: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}


/*========== Responsive typography ==========*/
@media screen and (min-width: 1152px) {
  :root {
    --biggest-font-size: 5.5rem;
    --h1-font-size: 2.5rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: .875rem;
    --smaller-font-size: .813rem;
  }
}

/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  color: var(--text-color);
}

body {
  background-color: var(--body-color);
}

button,
input {
  outline: none;
  border: none;
}

h1, h2, h3, h4 {
  color: var(--title-color);
  font-family: var(--second-font);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.container {
  max-width: 1120px;
  margin-inline: 1.5rem;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.section {
  padding-block: 5rem 1rem;
}

.section__title {
  text-align: center;
  font-size: var(--h1-font-size);
  margin-bottom: 1.5rem;
}

.main {
  overflow: hidden; /* For animation ScrollReveal */
}

/*=============== HEADER & NAV ===============*/
.header{
  position: fixed;
  width: 100%;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 1000;
}

.nav{
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo{
  width: 160px;
}

.nav__toggle,
.nav__close{
  display: flex;
  font-size: 1.25rem;
  color: var(--title-color);
  cursor: pointer;
}

/* Navigation for mobile devices */
@media screen and (max-width: 1023px) {
  .nav__menu{
    position: fixed;
    top: -100%;
    left: 0;
    background-color: hsla(0, 0%, 0%, .3);
    width: 100%;
    padding-block: 4rem;
    backdrop-filter: blur(24px);
   -webkit-backdrop-filter: blur(24px);  /* For safari */
   transition: top .4s;
  }
}

.nav__list{
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 2.5rem;
}

.nav__link{
  position: relative;
  color: var(--title-color);
  font-family: var(--second-font);
  font-weight: var(--font-medium);
}

.nav__link::after{
  content: '';
  width: 0;
  height: 2px;
  background-color: var(--title-color);
  position: absolute;
  left: 0;
  bottom: -.5rem;
  transition: width .3s;
}

.nav__link:hover::after{
  width: 70%;
}

.nav__close{
  position: absolute;
  top: 1rem;
  right: 1.5rem;
}

/* Show menu */
.show-menu{
  top: 0;
}

/* Add blur to header */
.blur-header::after{
  content: '';
  position: absolute;
  width: 1000%;
  height: 100%;
  background-color: hsla(0, 0%, 0%, .3);
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(24px);
  top: 0;
  left: 0;
  z-index: -1;
}

/* Active link */
.active-link::after{
  width: 70%;
}

/*=============== HOME ===============*/
html body {
  min-height: 100%;
}
section#scroll {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}
section#scroll:before {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1400px;
  background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.3) 80%, #000 100%);
  z-index: 100;
}
section#scroll img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}
#two {
  z-index: 10;
  animation: animate calc(10s * var(--i)) linear infinite;
}
#text {
  position: relative;
  color: #fff;
  z-index: 2;
  font-size: 5em;
  text-align: center;
  padding-top: 250px;
  text-transform: uppercase;
  letter-spacing: 2px;
}


/* animiton */

@keyframes animate {
  
  0% {
      opacity: 0;
      transform: scale(1);
  }
  20%,75% {
      opacity: 0.5;
  }
  100% {
      transform: scale(3);
  }
}

/* animiton */

.home__cards{
  margin-top: 8.5rem;
  grid-template-columns: 240px;
  justify-content: center;
}

.home__card{
  position: relative;
  overflow: hidden;
}

.home__card-img{
  transition: transform .4s;
  border-radius: .5rem;
}

.home__card-shadow{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg,
              hsla(0, 0%, 0%, 0) 50%,
              hsl(0, 0%, 0%) 125%);
}

.home__card-title{
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  font-size: var(--h3-font-size);
  z-index: 1;
}

.home__card:hover .home__card-img{
  transform: scale(1.2);
}

/*=============== BUTTON ===============*/
.button{
  background-color: #029EDA;
  padding: 1.25rem 1.5rem;
  color: var(--title-color);
  border-radius: .5rem;
  display: inline-flex;
  align-items: center;
  column-gap: .5rem;
  font-family: var(--second-font);
  font-weight: var(--font-semi-bold);
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(24px); /* For safari */
}

.button i{
  font-size: 1.25rem;
  transition: transform .4s;
  font-weight: initial;
}

.button:hover i{
  transform: translateX(.25rem);
}

/*=============== ABOUT ===============*/
.about__container{
  row-gap: 3rem;
}

.about__data{
  text-align: center;
}

.about__description{
  margin-bottom: 2rem;
}

.about__image{
  position: relative;
  justify-self: center;
  overflow: hidden;
}

.about__img{
  width: 300px;
  border-radius: .5rem;
  transition: transform .4s;
}

.about__shadow{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg,
              hsla(0, 0%, 0%, 0) 50%,
              hsl(0, 0%, 0%) 125%)
}

.about__image:hover .about__img{
  transform: scale(1.2);
}

/*=============== POPULAR ===============*/
.popular__container{
  padding-top: 1.5rem;
  grid-template-columns: 240px;
  justify-content: center;
  row-gap: 2.5rem;
}
.popular__container2{
  padding-top: 1.5rem;
  grid-template-columns: 240px;
  justify-content: center;
  row-gap: 2.5rem;
}

.popular__image{
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;
}

.popular__img{
  border-radius: .5rem;
  transition: transform .4s;
}

.popular__shadow{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg,
            hsla(0, 0%, 0%, 0) 50%,
            hsl(0, 0%, 0%) 125%);
}

.popular__title{
  font-size: var(--h3-font-size);
  margin-bottom: .5rem;
}

.popular__location{
  display: inline-flex;
  align-items: center;
  column-gap: .25rem;
  font-size: small;
  font-family: var(--second-font);
  font-weight: 500;
}

.popular__location i{
  font-size: 1rem;
}

.popular__image:hover .popular__img{
  transform: scale(1.2);
}

/*=============== EXPLORE ===============*/
.explore{
  position: relative;
}

.explore__image{
  position: absolute;
  overflow: hidden;
}

.explore__img{
  width: 100%;
  height: 333px;
  object-fit: cover;
  object-position: center;
}

.explore__shadow{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg,
            hsl(0, 0%, 0%) 5%,
            hsla(0, 0%, 0%, 0) 40%,
            hsla(0, 0%, 0%, 0) 60%,
            hsl(0, 0%, 0%) 92%);
}

.explore__content{
  position: relative;
  padding-top: 16rem;
  text-align: center;
  row-gap: 2.5rem;
}

.explore__user{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: .5rem;
}

.explore__perfil{
  width: 30px;
  border-radius: 50%;
}

.explore__name{
  font-size: var(--small-font-size);
  color: var(--title-color);
}
/*=============== JOIN ===============*/
.join__container{
  row-gap: 3rem;
  padding-bottom: 2.5rem;
}

.join__data{
  text-align: center;
}

.join__description{
  margin-bottom: 2rem;
}

.join__form{
  display: grid;
  row-gap: 1rem;
}

.join__input{
  padding: 1.25rem 1rem;
  background-color: var(--container-color);
}

.join__button{
  cursor: pointer;
}

.join__image{
  position: relative;
  justify-self: center;
  overflow: hidden;
}

.join__img{
  width: 300px;
  border-radius: .5rem;
  transition: transform .4s;
}

.join__shadow{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg,
            hsla(0, 0%, 0%, 0) 50%,
            hsl(0, 0%, 0%) 125%);
}

.join__image:hover .join__img{
  transform: scale(1.2);
}

/*=============== FOOTER ===============*/
.footer{
  background-color: var(--container-color);
  padding-block: 3.5rem 2rem;
}

.footer__container{
  row-gap: 2rem;
  text-align: center;
}

.footer__image{
  display: flex;
  justify-content: center;
}

.footer__title{
  width: 200px;
  margin-top: -3rem;
}

.footer__education{
  font-size: var(--normal-font-size);
}

.footer__social{
  display: flex;
  justify-content: center;
  column-gap: 1.25rem;
}

.footer__social-link{
  display: flex;
  background-color: var(--first-color-alt);
  padding: .5rem;
  color: var(--title-color);
  font-size: 1.5rem;
  transition: transform .4s;
}

.footer__social-link:hover{
  transform: translateY(-.25rem);
}

.footer__copy{
  font-size: var(--smaller-font-size);
  margin-top: 2rem;
}

/*=============== SCROLL BAR ===============*/
::-webkit-scrollbar{
  width: .6rem;
  border-radius: .5rem;
  background-color: hsl(0, 0%, 10%);
}

::-webkit-scrollbar-thumb{
  border-radius: .5rem;
  background-color: hsl(0, 0%, 20%);
}

::-webkit-scrollbar-thumb:hover{
  background-color: hsl(0, 0%, 30%);
}

/*=============== SCROLL UP ===============*/
.scrollup{
  position: fixed;
  right: 1rem;
  bottom: -50%;
  /*background-color: hsla(0, 0%, 100%, .1);*/
  background-color: #0cc553;
  padding: 10px;
  display: inline-flex;
  color: var(--title-color);
  border-radius: 99rem;
  font-size: 2.25rem;
  backdrop-filter: blur(24px);
  -webkit-backdrop-filter: blur(24px); /* For safari */
  z-index: var(--z-tooltip);
  transition: bottom .4s, tramsform .4s;
}

.scrollup:hover{
  transform: translateY(-.25rem);
}

/* Show Scroll Up */
.show-scroll{
  bottom: 3rem;
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 340px) {
  .container{
    margin-inline: 1rem;
  }

  .footer__data{
    grid-template-columns: max-content;
  }

  #text{
    font-size: 50px;
  }
}

@media screen and (max-width: 420px){
  #text{
    font-size: 50px;
  }
}

/* For medium devices */
@media screen and (min-width: 576px) {
  .home__container,
  .about__container,
  .explore__container,
  .join__container{
    grid-template-columns: 380px;
    justify-content: center;
  }
}

@media screen and (min-width: 768px) {
  .home__cards{
    grid-template-columns: repeat(2, 240px);
  }

  .about__container,
  .join__container{
    grid-template-columns: repeat(2, 350px);
    align-items: center;
  }

  .about__data,
  .about__data .section__title,
  .join__data,
  .join__data .section__title{
    text-align: initial;
  }

  .popular__container{
    grid-template-columns: repeat(2, 240px);
  }
  .popular__container2{
    grid-template-columns: repeat(2, 240px);
  }

  .explore__img{
    width: 100vw;
  }

  .join__image{
    order: -1;
  }
}

/* For large devices */
@media screen and (min-width: 1023px) {
  .nav__close,
  .nav__toggle{
    display: none;
  }
  .nav__list{
    flex-direction: row;
    column-gap: 4rem;
  }
  .nav__logo{
    width: 250px;
  }

  .popular__container{
    grid-template-columns: repeat(3, 240px);
  }
  .popular__container2{
    grid-template-columns: repeat(3, 240px);
  }
}

@media screen and (min-width: 1152px) {
  .container{
    margin-inline: auto;
  }

  .section{
    padding-block: 7rem 2rem;
  }

  .nav{
    height: calc(var(--header-height) + 1.5rem);
  }
  .blur-header::after{
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px); /* For safari */
  }

  .home__container{
    grid-template-columns: initial;
    justify-content: initial;
    row-gap: 4rem;
    padding-top: 3rem;
  }
  .home__shadow{
    height: 980px;
  }
  .home__data{
    text-align: initial;
    width: 480px;
  }
  .home__subtitle{
    font-size: var(--h2-font-size);
  }
  .home__description{
    margin-bottom: 2.5rem;
  }
  .home__cards{
    margin-top: 2rem;
    grid-template-columns: repeat(4, 260px);
  }
  .home__card-title{
    left: 1.5rem;
    bottom: 1.5rem;
  }

  .button{
    column-gap: 1.5rem;
    cursor: pointer;
  }

  .about__container{
    grid-template-columns: 400px 460px;
    column-gap: 8.5rem;
    padding-block: 1rem;
  }
  .about__description{
    margin-bottom: 3rem;
  }
  .about__img{
    width: 460px;
  }

  .popular__container{
    grid-template-columns: repeat(3, 300px);
    column-gap: 3rem;
    padding-top: 1rem;
  }
  .popular__container2{
    grid-template-columns: repeat(4, 1fr);
    column-gap: 3rem;
    padding-top: 1rem;
  }
  .popular__title{
    font-size: var(--h2-font-size);
  }
  .popular__location{
    font-size: var(--normal-font-size);
  }

  .explore__img{
    height: 600px;
  }
  .explore__content{
    padding-top: 28rem;
    grid-template-columns: 670px 1fr;
  }
  .explore__data,
  .explore__data .section__title{
    text-align: initial;
  }
  .explore__user{
    justify-content: flex-end;
    align-self: flex-end;
    margin-bottom: 1.25rem;
  }

  .join__container{
    grid-template-columns: 460px 340px;
    column-gap: 8.5rem;
    padding-block: 1rem 5rem;
  }
  .join__img{
    width: 460px;
  }
  .join__description{
    margin-bottom: 3rem;
  }

  .footer{
    padding-block: 4.5rem 4rem;
  }
  .footer__container{
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    text-align: initial;
  }
  .footer__title{
    width: 250px;
    margin-top: -3rem;
  }
  .footer__social{
    column-gap: 1.5rem;
  }
  .footer__copy{
    margin: 0;
    justify-self: flex-end;
  }
  .scrollup{
    right: 3rem;
  }

  .scrollup{
    right: 3rem;
  }
}

/* Wrapper to center content */
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

/* Travel Info Container */
.travel-info-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 28rem; /* Approximate equivalent to w-2/3 */
  width: 66.67%;
  text-align: center;
  background-color: white;
  border-radius: 0.5rem; /* rounded-lg */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Header */
.travel-info-header {
  background-color: #2563eb; /* bg-blue-600 */
  padding: 1rem; /* p-4 */
  border-top-left-radius: 0.5rem; /* rounded-t-lg */
  border-top-right-radius: 0.5rem; /* rounded-t-lg */
}

.travel-info-title {
  font-size: 1.5rem; /* text-2xl */
  font-weight: bold; /* font-bold */
  color: white;
}

.travel-info-dates {
  font-weight: 600; /* font-semibold */
  color: white;
}

/* Schedule */
.travel-info-schedule {
  text-align: center;
}

.travel-info-schedule-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #93c5fd; /* bg-blue-300 */
  padding: 0.5rem; /* Approximate padding */
}

.travel-info-label {
  font-weight: 600; /* font-semibold */
  color: #1d4ed8; /* text-blue-700 */
  margin-right: 0.5rem; /* mr-2 */
}

.travel-info-value {
  color: #2563eb; /* text-blue-600 */
  margin-left: 0.5rem; /* ml-2 */
}

/* Details */
.travel-info-details {
  background-color: white;
  border-bottom-left-radius: 0.5rem; /* rounded-b-lg */
  border-bottom-right-radius: 0.5rem; /* rounded-b-lg */
}

.travel-info-details ul {
  color: #3b82f6; /* text-blue-500 */
  list-style-type: disc;
  list-style-position: inside;
  padding: 1rem; /* p-4 */
  margin: 0;
}

.travel-info-details li {
  margin: 0.25rem 0; /* my-1 */
}

.travel-info-highlight {
  font-weight: 600; /* font-semibold */
}

.text-center {
  text-align: center;
}

/* Custom height */
.custom-height {
  height: 2.5rem; /* h-10 translates to 2.5rem */
}
